import React from 'react'
import {Helmet} from "react-helmet"
import {Link} from 'gatsby'
import Navbar from '../components/oneTimeComponents/NavigationBar'
import RaccoltaDocumentazione from '../components/RaccoltaIT/RaccoltaDocumentazione'
import './stile.css'

export default function PublicArea(){
    const stileButton ={
        backgroundColor:"white",
        color:"black",
        border:"1px solid black"
    }
    return(
        <div className="pageContainer">
            <Helmet>
                <title>Documentazione</title>
            </Helmet>
            <Navbar page="EN" sezione="2"/>
            <br/>
            <RaccoltaDocumentazione page="EN"/>
            <br/><br/>
            <Link to="/EN">
            <button className="ButtonHome" style={stileButton}>
                HOME
            </button>
            </Link>
        </div>
    )
}